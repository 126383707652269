<template>
    <h5>Library(Recorded Session)</h5>
    <div class="grid">
      <Toast />
          <loading
      :active="isLoadingModel"
      :can-cancel="true"
      
      color="#ffc107"
      :is-full-page="fullPage"
    ></loading>
      <div class="col-12 lg:col-6 xl:col-4">
        <div v-on:click="go_to_student()">
          <div class="card mb-0">
            <div class="flex justify-content-between mb-4">
              <div>
                <span class="block text-500 font-medium mb-3">Mathematics</span>
                <div class="text-900 font-medium text-xl">{{ Mathematics }}</div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-blue-100 border-round"
                style="width: 2.5rem; height: 2.5rem"
              >
                <i class="pi pi-fw pi-user text-blue-500 text-xl"></i>
              </div>
            </div>
            <!-- <span class="text-green-500 font-medium">24 new </span>
                  <span class="text-500">since last visit</span> -->
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-4">
        <div v-on:click="go_to_student()">
          <div class="card mb-0">
            <div class="flex justify-content-between mb-4">
              <div>
                <span class="block text-500 font-medium mb-3">Physics</span>
                <div class="text-900 font-medium text-xl">{{ Physics }}</div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-blue-100 border-round"
                style="width: 2.5rem; height: 2.5rem"
              >
                <i class="pi pi-fw pi-user text-blue-500 text-xl"></i>
              </div>
            </div>
            <!-- <span class="text-green-500 font-medium">24 new </span>
                  <span class="text-500">since last visit</span> -->
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-4">
        <div v-on:click="go_to_student()">
          <div class="card mb-0">
            <div class="flex justify-content-between mb-4">
              <div>
                <span class="block text-500 font-medium mb-3">Chemistry</span>
                <div class="text-900 font-medium text-xl">{{ Chemistry }}</div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-blue-100 border-round"
                style="width: 2.5rem; height: 2.5rem"
              >
                <i class="pi pi-fw pi-user text-blue-500 text-xl"></i>
              </div>
            </div>
            <!-- <span class="text-green-500 font-medium">24 new </span>
                  <span class="text-500">since last visit</span> -->
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-4">
        <div v-on:click="go_to_student()">
          <div class="card mb-0">
            <div class="flex justify-content-between mb-4">
              <div>
                <span class="block text-500 font-medium mb-3">Botany</span>
                <div class="text-900 font-medium text-xl">{{ Botany }}</div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-blue-100 border-round"
                style="width: 2.5rem; height: 2.5rem"
              >
                <i class="pi pi-fw pi-user text-blue-500 text-xl"></i>
              </div>
            </div>
            <!-- <span class="text-green-500 font-medium">24 new </span>
                  <span class="text-500">since last visit</span> -->
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-4">
        <div v-on:click="go_to_student()">
          <div class="card mb-0">
            <div class="flex justify-content-between mb-4">
              <div>
                <span class="block text-500 font-medium mb-3">Zoology</span>
                <div class="text-900 font-medium text-xl">{{ Zoology }}</div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-blue-100 border-round"
                style="width: 2.5rem; height: 2.5rem"
              >
                <i class="pi pi-fw pi-user text-blue-500 text-xl"></i>
              </div>
            </div>
            <!-- <span class="text-green-500 font-medium">24 new </span>
                  <span class="text-500">since last visit</span> -->
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-4">
        <div v-on:click="go_to_student()">
          <div class="card mb-0">
            <div class="flex justify-content-between mb-4">
              <div>
                <span class="block text-500 font-medium mb-3"
                  >Introduction From Project Manager</span
                >
                <div class="text-900 font-medium text-xl">
                  {{ Introduction_From_Project_Manager }}
                </div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-blue-100 border-round"
                style="width: 2.5rem; height: 2.5rem"
              >
                <i class="pi pi-fw pi-user text-blue-500 text-xl"></i>
              </div>
            </div>
            <!-- <span class="text-green-500 font-medium">24 new </span>
                  <span class="text-500">since last visit</span> -->
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import ProductService from "../service/ProductService";
  import apis from "@/apis";
  export default {
    data() {
      return {
        Mathematics: 0,
        Physics: 0,
        Chemistry: 0,
        Botany: 0,
        Zoology: 0,
        Introduction_From_Project_Manager: 0,
        isLoadingModel:false,
              fullPage:true,
      };
    },
    async created() {
      this.isLoadingModel=true;
      var data = {
        type: "studymaterial",
        MOBILE: localStorage.getItem("mobile"),
      };
  
      var promise = apis.studentsubjectlist(data);
      promise.then((response) => {
          this.isLoadingModel=false;
        if (response.data) {
          for (let Z = 0; Z < response.data.length; Z++) {
            if (response.data[Z].subject == "Mathematics") {
              this.Mathematics = response.data[Z].count;
            }
            if (response.data[Z].subject == "Physics") {
              this.Physics = response.data[Z].count;
            }
            if (response.data[Z].subject == "Chemistry") {
              this.Chemistry = response.data[Z].count;
            }
            if (response.data[Z].subject == "Botany") {
              this.Botany = response.data[Z].count;
            }
            if (response.data[Z].subject == "Zoology") {
              this.Zoology = response.data[Z].count;
            }
            if (response.data[Z].subject == "Introduction from Project Manager") {
              this.Introduction_From_Project_Manager = response.data[Z].count;
            }
          }
        }
       
      });
    },
    async mounted() {},
    methods: {},
  };
  </script>
  
  <style></style>
  